import React, { useState } from 'react'
import { graphql } from 'gatsby'
import axios from 'axios'
import tw, { styled } from 'twin.macro'
import logo from '../images/Quantics_Logo.svg'
import { LocaleContext } from '../components/layout'
import SEO from '../components/SEO/SEO'
import { useForm } from 'react-hook-form'
import { QForm, SmallSolidTurquoiseInput } from '../utils/styles'
import { withPreview } from 'gatsby-source-prismic'
import Mail from '../images/Mail.svg'
import Phone from '../images/Phone.svg'
// styles

// markup
const ContactPage = ({ data: { prismicContact }, pageContext: { locale } }) => {
  const lang = React.useContext(LocaleContext)
  const i18n = lang.i18n[lang.locale]
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()
  const [requested, setRequested] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const onSubmit = async (data) => {
    setSubmitting(true)
    const response = await axios.post('/.netlify/functions/meeting', data)
    if (response.data.hasOwnProperty('success') && response.data.success) {
      setRequested(true)
    }
    setSubmitting(false)
  }
  return (
    <main>
      <SEO
        title={prismicContact.data.meta_title}
        desc={prismicContact.data.meta_description}
        locale={locale}
      />
      <div tw="container py-40">
        <div tw="grid md:grid-cols-2 items-center">
          <div>
            <h1 tw="text-navy">{prismicContact.data.headline.text}</h1>
            <div
              dangerouslySetInnerHTML={{
                __html: prismicContact.data.description.html,
              }}
              tw="text-ocean mt-5 text-xl max-w-md"></div>
            <div tw="hidden md:block">
              <div tw="w-48 py-10">
                <img src={logo} />
              </div>
              <div tw="w-64 py-10 font-black text-navy border-t border-navy border-opacity-20">
                <img src={Phone} tw="w-6 inline mr-3" />
                <span>{prismicContact.data.phone}</span>
              </div>
              <div tw="w-64 py-10 font-black text-navy border-t border-navy border-opacity-20">
                <img src={Mail} tw="w-6 inline mr-3" />
                <span>{prismicContact.data.email}</span>
              </div>
            </div>
          </div>
          {requested ? (
            <div tw="text-center">
              <h2 tw="text-navy">{i18n.form.message.headline}</h2>
              <p tw="text-xl text-ocean mt-4">{i18n.form.message.desc}</p>
            </div>
          ) : (
            <div>
              <QForm>
                <form onSubmit={handleSubmit(onSubmit)} tw="flex flex-col">
                  <h3>Get in touch</h3>

                  <input
                    {...register('firstName', { required: true })}
                    placeholder="First name"
                    className="q-input"
                  />
                  {errors.firstName && (
                    <span tw="mb-2 text-red">First name is required</span>
                  )}
                  <input
                    {...register('lastName', { required: true })}
                    placeholder="Last name"
                    className="q-input"
                  />
                  {errors.lastName && (
                    <span tw="mb-2 text-red">Last name is required</span>
                  )}
                  <input
                    {...register('workEmail', {
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    })}
                    placeholder="Work email"
                    className="q-input"
                  />
                  {errors.workEmail?.type === 'required' && (
                    <span tw="mb-2 text-red">Work email is required</span>
                  )}
                  {errors.workEmail?.type === 'pattern' && (
                    <span tw="mb-2 text-red">Invalid email address</span>
                  )}
                  <input
                    {...register('companyName', { required: false })}
                    placeholder="Company name"
                    className="q-input"
                  />
                  <input
                    {...register('jobTitle', { required: false })}
                    placeholder="Job title"
                    className="q-input"
                  />
                  <textarea
                    {...register('message', { required: true })}
                    placeholder="Enter message or question here.."
                    className="q-textarea"
                  />
                  {errors.message && (
                    <span tw="mb-2 text-red">Message is required</span>
                  )}
                  <SmallSolidTurquoiseInput
                    type="submit"
                    tw="relative"
                    className={submitting ? 'button is-loading' : 'button'}
                    disabled={submitting}>
                    Contact Support
                  </SmallSolidTurquoiseInput>
                  <span tw="mt-6 text-sm">{i18n.form.notice}</span>
                </form>
              </QForm>
            </div>
          )}
          <div tw="md:hidden text-center">
            <div tw="w-64 m-auto py-10 font-black text-navy border-t border-navy border-opacity-20">
              <img src={Phone} tw="w-6 inline mr-3" />
              <span>{prismicContact.data.phone}</span>
            </div>
            <div tw="w-64 m-auto py-10 font-black text-navy border-t border-navy border-opacity-20">
              <img src={Mail} tw="w-6 inline mr-3" />
              <span>{prismicContact.data.email}</span>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default withPreview(ContactPage)

export const pageQuery = graphql`
  query ContactQuery($locale: String!) {
    prismicContact(lang: { eq: $locale }) {
      data {
        headline {
          text
        }
        meta_description
        meta_title
        phone
        email
        description {
          html
        }
      }
    }
  }
`
